import { createI18n } from 'vue-i18n';


function loadLocaleMessages() {
    const locales = require.context(
        "./locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
    );
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([a-z0-9]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

function getUserLocale() {
    const userLocale = navigator.language || navigator.userLanguage;
    const supportedLocales = ['en', 'es', 'it'];
    const defaultLocale = 'es';

    // Check if user's language is one of the supported locales
    return supportedLocales.includes(userLocale.split('-')[0])
        ? userLocale.split('-')[0]
        : defaultLocale;
}

export default new createI18n({
    locale: getUserLocale(),
    fallbackLocale: "es",
    messages: loadLocaleMessages(),
});
