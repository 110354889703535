import {createApp} from 'vue';
import App from './App.vue';
import router from './router/index';
import {Field, Form, ErrorMessage} from 'vee-validate';

// Import Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Import AdminLTE CSS
import 'admin-lte/dist/css/adminlte.min.css';
// Import Bootstrap and AdminLTE JS
import 'bootstrap';
import 'admin-lte';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

import ui from './plugins/ui';
import apiPlugin from './plugins/apiPlugin';
import toastPlugin from './plugins/toastPlugin';
import i18n from './i18n';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {fas} from '@fortawesome/free-solid-svg-icons'; // Import all solid icons
import {far} from '@fortawesome/free-regular-svg-icons'; // Import all regular icons

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import './assets/style.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Select2 from 'vue3-select2-component';

import cart from './cart';

const app = createApp(App);

import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';

app.use(FloatingVue);

app.use(VueSweetalert2);

import eventBus from './plugins/event-bus';
app.use(eventBus);

library.add(fas); // Add all solid icons to the library
library.add(far); // Add all solid icons to the library

app.component('select2-component', Select2);

app.component('FieldComponent', Field);
app.component('FormComponent', Form);
app.component('ErrorMessage', ErrorMessage);
app.component('font-awesome-icon', FontAwesomeIcon); // Register the FontAwesomeIcon component globally
app.component('VueSlider', VueSlider);

import '@vuepic/vue-datepicker/dist/main.css';
import cryptoPlugin from './plugins/crypto';

app.use(cryptoPlugin);
app.use(cart);
app.use(ui);
app.use(apiPlugin);
app.use(router);
app.use(i18n);
app.use(Toast, {
	timeout: 5000,
});
app.use(toastPlugin);
app.mount('#app');
