import { createRouter, createWebHistory } from 'vue-router';

//Define routes

import DashboardLayout from "@/components/skeleton/BaseLayout.vue";
import NotFound from "@/pages/NotFoundPage.vue";

// Admin pages
const OrderList = () => import("@/pages/OrderList.vue");
const ReservasPage = () => import("@/pages/ReservasPage.vue");
const PatientList = () => import("@/pages/PatientList.vue");
const CatalogoPage = () => import("@/pages/CatalogoPage.vue");
const CheckoutPage = () => import("@/pages/CheckoutPage.vue");

//Login
const LoginPage = () => import("@/pages/LoginPage.vue");

const routes = [
    {
        path: "/login",
        component: LoginPage,
        beforeEnter: (to, from, next) => {
            // Add any beforeEnter logic if needed
            next();
        }
    },
    {
        path: "/",
        component: DashboardLayout,
        meta: {
            requiresAuth: true,
            roles: ["ROLE_CENTRO_WEB"],
        },
        children: [
            {
                path: "/",
                name: "orders",
                component: OrderList,
                icon: "cart-plus",
                meta: {
                    requiresAuth: true,
                    roles: ["ROLE_CENTRO_WEB"],
                },
            },
            {
                path: "/patients",
                name: "patients",
                component: PatientList,
                icon: "users",
                meta: {
                    requiresAuth: true,
                    roles: ["ROLE_CENTRO_WEB"],
                },
            },
            {
                path: "/checkout",
                name: "checkout",
                component: CheckoutPage,
                icon: "users",
                meta: {
                    display: false,
                    requiresAuth: true,
                    roles: ["ROLE_CENTRO_WEB"],
                },
            },
            {
                path: "/new-order",
                name: "catalogo",
                component: CatalogoPage,
                icon: "search",
                meta: {
                    requiresAuth: true,
                    roles: ["ROLE_CATALOGO"],
                },
            },
            {
                path: "/sample-reservations",
                name: "reservas",
                component: ReservasPage,
                icon: "lock",
                meta: {
                    requiresAuth: true,
                    roles: ["ROLE_CATALOGO"],
                },
            },
        ],
    },
    {
        path: '/preorder/:id',
        component: () => import('@/pages/PreorderPage.vue'),
        name: 'preorder',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/payment/:id',
        component: () => import('@/pages/PaymentPage.vue'),
        name: 'payment',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/payment-ok',
        component: () => import('@/pages/OKPage.vue'),
        name: 'payment_ok',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/payment-ko',
        component: () => import('@/pages/KOPage.vue'),
        name: 'payment_ko',
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: {
            beforeRouteEnter(to, from, next) {
                localStorage.removeItem("gametia_user");
                next("/login");
            }
        }
    },
    { path: '/:catchAll(.*)', component: NotFound },
];



// create router instance
const router = createRouter({
    history: createWebHistory(),
    routes,
    linkExactActiveClass: "active",
    scrollBehavior: (to) => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    },
});

// navigation guard
router.beforeEach((to, from, next) => {
    //const publicPages = ["/login"];
    //const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem("gametia_user");

    if (to.meta.requiresAuth && !loggedIn) {
        return next("/login");
    }

    if (loggedIn) {
        let user = JSON.parse(localStorage.getItem("gametia_user"));
        if (to.meta.roles) {
            const found = user.roles.some((r) => to.meta.roles.indexOf(r) >= 0);
            if (!found) {
                return next("/login");
            }
        }
    }

    next();
});

export default router;
