// api.js
import axios from 'axios';

const baseURL = process.env.VUE_APP_API_URL;
// Update with your API base URL
const api = axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json',
	},
});

// Add a request interceptor
api.interceptors.request.use(
	(config) => {
		const localUser = localStorage.getItem('gametia_user');
		if (localUser) {
			let token = JSON.parse(localUser).token;
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		// Handle request errors here
		return Promise.reject(error);
	}
);

// Add a response interceptor
api.interceptors.response.use(
	(response) => {
		// Do something with successful responses
		return response;
	},
	(error) => {
		// Handle error responses here
		if (error.response && error.response.status === 401) {
			// Handle 401 Unauthorized error
			localStorage.removeItem('gametia_user');
			// You can redirect the user to the login page or handle the error as needed
			window.location.reload();
		}
		return Promise.reject(error);
	}
);

//User management
export const loginUser = async (userData) => {
	try {
		const response = await api.post('/login_check', userData);
		localStorage.setItem('gametia_user', JSON.stringify(response.data));
		return JSON.parse(JSON.stringify(response.data));
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const getAlerts = async () => {
	try {
		const response = await api.get('/alerts');
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

//Pedidos
export const getOrders = async () => {
	try {
		const response = await api.get('/orders');
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const createOrder = async (order) => {
	try {
		const response = await api.post('/new-order', order, {});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const isDuplicatedPatient = async (patient) => {
	try {
		const response = await api.post('/check-duplicate', patient, {});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const asignarPaciente = async (idPedido, idPaciente) => {
	try {
		const response = await api.get('/assign-patient-order/' + idPedido + '/' + idPaciente, {});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const getOrderInfo = async (idOrder) => {
	try {
		const response = await api.get('/order-info/' + idOrder);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const getPreorderInfo = async (idOrder, pedido) => {
	try {
		//If pedido is set, its a POST
		if (pedido) {
			const response = await api.post('/preorder/' + idOrder, pedido);
			return response.data;
		}
		const response = await api.get('/preorder/' + idOrder);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const aceptarPropuesta = async (idOrder, data) => {
	try {
		const response = await api.post('/accept-proposal/' + idOrder, data);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const updatePatientInfo = async (idPaciente, data) => {
	try {
		const response = await api.post('/patient-info/' + idPaciente, data);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const getOrder = async (idOrder) => {
	try {
		const response = await api.get('/order/' + idOrder);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

//Pacientes
export const getPatients = async () => {
	try {
		const response = await api.get('/patients');
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const getPacientesParaReserva = async () => {
	try {
		const response = await api.get('/patients-for-reservation');
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const getDonantesParaReserva = async (idPaciente) => {
	try {
		const response = await api.get('/donors-for-reservation/' + idPaciente);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const savePatient = async (patient) => {
	try {
		const response = await api.post('/patient', patient);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

//Catalogo
export const buscarDonantes = async (filtro) => {
	try {
		const response = await api.post('/find-donor', filtro);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const getMotRanges = async (idDonante, idMuestra) => {
	try {
		const response = await api.get('/get-mot/' + idDonante + '/' + idMuestra);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

//Embarazos
export const notificarAusencia = async (idPedido) => {
	try {
		const response = await api.get('/no-pregnancy/' + idPedido);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const notificarEmbarazo = async (idPedido, data) => {
	try {
		const response = await api.post('/pregnancy/' + idPedido, data);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const notificarResolucion = async (idPedido, idEmbarazo, data) => {
	try {
		const response = await api.post('/pregnancy-result/' + idPedido + '/' + idEmbarazo, data);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

//reservas

export const getReservas = async () => {
	try {
		const response = await api.get('/reservations');
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const nuevaReserva = async (reserva) => {
	try {
		const response = await api.post('/new-reservation', reserva);
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

//albaran

export const getAlbaran = async (id) => {
	try {
		const response = await api.get('/albaran/' + id, {responseType: 'blob'});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const getFactura = async (id) => {
	try {
		const response = await api.get('/invoice/' + id, {responseType: 'blob'});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

//General
export const getServices = async (idLanding) => {
	try {
		if (idLanding) {
			const response = await api.get('/get-services/?landing=' + idLanding);
			return response.data;
		}
		const response = await api.get('/get-services');
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const getFestivos = async () => {
	try {
		const response = await api.get('/get-non-working-days');
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const downloadSEC = async () => {
	try {
		//Response type blob
		const response = await api.get('/download-sec', {responseType: 'blob'});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const downloadFile = async (id, idPedido) => {
	try {
		//Response type blob
		const response = await api.get('/download-file/' + id + '/' + idPedido, {responseType: 'blob'});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const descargarCIMatching = async (locale) => {
	try {
		//Response type blob
		const response = await api.get('/ci-matching/' + locale, {responseType: 'blob'});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};

export const descargarManual = async (locale) => {
	try {
		//Response type blob
		const response = await api.get('/guide/' + locale, {responseType: 'blob'});
		return response.data;
	} catch (error) {
		throw new Error(error.response.data.message);
	}
};
